.imageContainer {
    position: relative;
    height: 100%;
    width: 3.6rem;
    max-width: 3.8rem;
}

.imageButton {
    position: absolute;
    width: 3.6rem;
    max-height: 5rem;
    height: 100%;
    z-index: 5;
}

.buttonOverlay1 {
    position: absolute;
    width: 1.8rem;
    height: 100%;
    max-height: 5rem;
    overflow: hidden;
    transform-origin: right;
    transform: scale(-1) rotate(180deg) translate(1.80rem,0);
}

.buttonOverlay2 {
    position: absolute;
    width: 1.8rem;
    max-height: 5rem;
    height: 100%;
    overflow: hidden;
    transform: scale(-1) rotate(180deg)
}
.themeButton1 {
    position: absolute;
    top: 0;
    left: -1.80rem;
    cursor:pointer;
    border:none;
    width:auto;
    overflow:hidden;
    max-width: 4rem;
    max-height: 5rem;
    height: auto;
    object-fit: contain;
    filter: var(--filter-thing1);
    transition: 1s filter linear;
    
}

 .themeButton2 {
    position: absolute;
    top: 0;
    left: 0rem;
    cursor:pointer;
    border:none;
    width:auto;
    overflow:hidden;
    max-width: 4rem;
    max-height: 5rem;
    height: auto;
    object-fit: contain;
    filter: var(--filter-thing2);
    transition: 1s filter linear;
}
