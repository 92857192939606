.card {
  width: auto;
  height: 100%;
  background-color: #fff;
  background: linear-gradient(var(--horde-secondary), var(--horde-secondary-gradiant));
  box-shadow: 0 8px 16px -8px rgba(0,0,0,0.4);
  border-radius: 6px;
  overflow: hidden;
  position: relative;
  /* margin: 1.5rem; */
  transition: transform 0.4s;
  cursor: pointer;
}

.card h1 {
  text-align: center;
}

.card h2 {
  margin-left: 1rem;
  margin-top: 1rem;
}

.additional h2 {
  color: var(--text-on-secondary);
}

.card .additional {
  position: absolute;
  width: 100%;
  height: 95%;
  background: linear-gradient(var(--horde-primary), var(--horde-primary-gradiant));
  /* transition: height 0.4s; */
  overflow: hidden;
  z-index: 2;
}

.additional p {
  font-size: 1.2rem;
  margin: 0 1rem 0 1.2rem;
}

.card .asdf {
    display:none;
  }

.card:hover {
    /* transform: translate(5px,-5px); */
}

.card:hover .additional {
  height: 100%;
  border-radius: 0 5px 5px 0;
}

.card .reveal {
  display: none;
}

.social-icon {
  width: 20vw;
  margin: auto;
}

.social-icon img {
  max-width: 100%;
}

.join {
  display: none;
}

.additional-container {
  display: grid;
  grid-template-columns: 2fr auto;
}


@media (min-width: 45em) { 
  
  .card .reveal {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 180px;
    right: 0;
    z-index: 1;
    box-sizing: border-box;
    padding: 1rem;
    padding-top: 0;
    display: block;
  }

  .card .asdf {
    width: 100%;
    height: calc(100% - 180px);
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
    box-sizing: border-box;
    padding: 1rem;
    padding-top: 0;
    display: block;
  }

  .card .additional {
    position: absolute;
    width: 100%;
    height: 180px;
    background: linear-gradient(var(--horde-primary), var(--horde-primary-gradiant));
    transition: height 0.4s;
    overflow: hidden;
    z-index: 2;
  }
  
  .join {
    width: 100%;
    display: flex;
    justify-content: center;
  }

}