@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@523&display=swap');

:root {
  /* font-size: 16px */
  --grid-row-setup: 2;
  --grid-column-setup: 2;
  --bg-primary: #424242;
  --bg-secondary: #7e7e7e;


  --horde-primary: #B7262B;
  --horde-primary-gradiant: #b32328;
  --text-on-bg: white;
  --text-on-secondary: white;

  --horde-secondary: #333333;
  --horde-secondary-gradiant: #363636;

  /* --horde-secondary: #acacac;
  --horde-secondary-gradiant: #838383; */

  --horde-bg: #121212;

  --alliance-primary: rgb(32, 32, 156);
  --alliance-secondary: rgb(223, 198, 59);

  --filter-thing1:  grayscale(0);
  --filter-thing2: grayscale(100%) contrast(40%);
  ;
}

[data-theme="alliance"] {
  --bg-primary: #424242;
  --bg-secondary: #7e7e7e;
  
  --horde-secondary-gradiant: #00539C;
  --horde-secondary: #00539C;
  --text-on-bg: white;
  --text-on-secondary: black;
  /* --horde-secondary: #005581;

  --horde-primary: #FFD662; */
  --horde-primary: #FFD662;
  --horde-primary-gradiant: #FDDB27;

  --filter-thing1: grayscale(100%);
  --filter-thing2: grayscale(0%);
}

body {
  margin: 0;
  font-family: 'Dosis', sans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--horde-primary)
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* horde
darkred: 6C171A
lightred: B7262B
brownish: B1A380
darkgrey: 4B443C
blashish: 29211F


alts
red1 9E0C29
red2 8B1432

*/


/*
https://community.home-assistant.io/t/material-dark-red-frontend-theme/100690
RedHeader: c33e37
CardBg: 333333

Font White: d3d3d3
or cfcfcf

Font Red: #bc3d37
or d32f2f
*/