@import url(https://fonts.googleapis.com/css2?family=Dosis:wght@523&display=swap);
:root {
  /* font-size: 16px */
  --grid-row-setup: 2;
  --grid-column-setup: 2;
  --bg-primary: #424242;
  --bg-secondary: #7e7e7e;


  --horde-primary: #B7262B;
  --horde-primary-gradiant: #b32328;
  --text-on-bg: white;
  --text-on-secondary: white;

  --horde-secondary: #333333;
  --horde-secondary-gradiant: #363636;

  /* --horde-secondary: #acacac;
  --horde-secondary-gradiant: #838383; */

  --horde-bg: #121212;

  --alliance-primary: rgb(32, 32, 156);
  --alliance-secondary: rgb(223, 198, 59);

  --filter-thing1:  grayscale(0);
  --filter-thing2: grayscale(100%) contrast(40%);
  ;
}

[data-theme="alliance"] {
  --bg-primary: #424242;
  --bg-secondary: #7e7e7e;
  
  --horde-secondary-gradiant: #00539C;
  --horde-secondary: #00539C;
  --text-on-bg: white;
  --text-on-secondary: black;
  /* --horde-secondary: #005581;

  --horde-primary: #FFD662; */
  --horde-primary: #FFD662;
  --horde-primary-gradiant: #FDDB27;

  --filter-thing1: grayscale(100%);
  --filter-thing2: grayscale(0%);
}

body {
  margin: 0;
  font-family: 'Dosis', sans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #B7262B;
  background-color: var(--horde-primary)
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* horde
darkred: 6C171A
lightred: B7262B
brownish: B1A380
darkgrey: 4B443C
blashish: 29211F


alts
red1 9E0C29
red2 8B1432

*/


/*
https://community.home-assistant.io/t/material-dark-red-frontend-theme/100690
RedHeader: c33e37
CardBg: 333333

Font White: d3d3d3
or cfcfcf

Font Red: #bc3d37
or d32f2f
*/
  .Main {
    display: grid;
    height: 100vh;
    /* padding-top: max(6rem, 15vmin); */
    justify-content: center;
    justify-items: center;
    /* row-gap: 15px; */
    /* column-gap: 15px; */

    /* grid-template-columns: minmax(20px, 1fr) repeat(2, minmax(0, 100px)) minmax(20px, 1fr); */
    
    grid-template-rows: 5rem 1fr 1fr 1fr;
    grid-template-columns: 1fr;

    grid-auto-flow: column;
    align-items: center;
}

.Reddit {
    width: 100%;
    height: 100%;
    grid-row: var(--grid-row-setup);
    grid-row: 2;
}
.Discord {
    width: 100%;
    height: 100%;
    grid-row: var(--grid-row-setup);
    grid-row: 3;
}
.WowForums {
    width: 100%;
    height: 100%;
    grid-row: var(--grid-row-setup);
    grid-row: 4;
}

.RowGroup {
    display: grid;
    justify-items: center;
    grid-auto-flow: row;
    grid-row-gap: 15px;
    row-gap: 15px;
}

.ColumnGroup {
    display: grid;
    align-items: center;
    grid-auto-flow: column;
    grid-column-gap: 15px;
    -webkit-column-gap: 15px;
            column-gap: 15px;
}

h1,h2,h3 {
    margin-top: 0px;
}


@media (min-width: 45em) {    
    .Main {
    grid-template-rows: 5rem 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    }
    .Reddit {
        grid-column: 1;
        grid-row: 2
    }
    .Discord {
        grid-column: 2;
        grid-row: 2
    }
    .WowForums {
        grid-column: 3;
        grid-row: 2
    }
    h3 {
        margin-top: 1rem;
    }
  }
.card {
  width: auto;
  height: 100%;
  background-color: #fff;
  background: linear-gradient(var(--horde-secondary), var(--horde-secondary-gradiant));
  box-shadow: 0 8px 16px -8px rgba(0,0,0,0.4);
  border-radius: 6px;
  overflow: hidden;
  position: relative;
  /* margin: 1.5rem; */
  transition: transform 0.4s;
  cursor: pointer;
}

.card h1 {
  text-align: center;
}

.card h2 {
  margin-left: 1rem;
  margin-top: 1rem;
}

.additional h2 {
  color: var(--text-on-secondary);
}

.card .additional {
  position: absolute;
  width: 100%;
  height: 95%;
  background: linear-gradient(var(--horde-primary), var(--horde-primary-gradiant));
  /* transition: height 0.4s; */
  overflow: hidden;
  z-index: 2;
}

.additional p {
  font-size: 1.2rem;
  margin: 0 1rem 0 1.2rem;
}

.card .asdf {
    display:none;
  }

.card:hover {
    /* transform: translate(5px,-5px); */
}

.card:hover .additional {
  height: 100%;
  border-radius: 0 5px 5px 0;
}

.card .reveal {
  display: none;
}

.social-icon {
  width: 20vw;
  margin: auto;
}

.social-icon img {
  max-width: 100%;
}

.join {
  display: none;
}

.additional-container {
  display: grid;
  grid-template-columns: 2fr auto;
}


@media (min-width: 45em) { 
  
  .card .reveal {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 180px;
    right: 0;
    z-index: 1;
    box-sizing: border-box;
    padding: 1rem;
    padding-top: 0;
    display: block;
  }

  .card .asdf {
    width: 100%;
    height: calc(100% - 180px);
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
    box-sizing: border-box;
    padding: 1rem;
    padding-top: 0;
    display: block;
  }

  .card .additional {
    position: absolute;
    width: 100%;
    height: 180px;
    background: linear-gradient(var(--horde-primary), var(--horde-primary-gradiant));
    transition: height 0.4s;
    overflow: hidden;
    z-index: 2;
  }
  
  .join {
    width: 100%;
    display: flex;
    justify-content: center;
  }

}
.navbar {
  grid-row: 1;
  grid-column: 1/4;
  height: 100%;
  width: 100%;
  
  
  background-color: var(--horde-secondary)
}

h1 {
  /* color: white;
  font-size: 1em; */
  display:none;
  margin:0;
}

.navbar-nav {
  list-style: none;
  height: 100%;
  padding: 0;
  margin: 0;
  display: grid;
  /* flex-direction: row; */
  grid-auto-flow: column;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
  align-items: center;
  width: 100%;

}

.nav-item {
  margin: 0 .5rem;
}

.nav-item-header {
  grid-column: 4
}

.nav-link {
  color: var(--text-on-bg);
  text-decoration: none;
  text-transform: uppercase;
  font-size: 1.5rem;
  -webkit-animation: 2s slideUp ease forwards .5s;
          animation: 2s slideUp ease forwards .5s;
  position: relative;
  align-self: center;
}

.theme-btn {
  align-self: flex-start;
  height: 100%;
}

.nav-link-active {
  color: var(--text-on-bg);
  text-decoration: none;
  text-transform: uppercase;
  font-size: 1.5rem;
  -webkit-animation: 2s slideUp ease forwards .5s;
          animation: 2s slideUp ease forwards .5s;
  position: relative;
}

.nav-link-active::before, .nav-link::before {
  content: '';
  position: absolute;
  height: 4px;
  width: 0px;
  left: 0;
  bottom: -.2rem;
  background: #ffffff;
  transition: all .5s ease;
}

.nav-link-active:before, .nav-link:hover:before {
  width: 100%;
}


@media (min-width: 45em) { 
  h1 {
    font-size: 1.8em;
    display: inline-block;
    margin-left: 1rem
  }

  .nav-item-header {
    grid-column: 1;
    justify-self:  start;
  }

  .navbar-nav {
    grid-template-columns: 4fr auto auto auto;
  }
}
.imageContainer {
    position: relative;
    height: 100%;
    width: 3.6rem;
    max-width: 3.8rem;
}

.imageButton {
    position: absolute;
    width: 3.6rem;
    max-height: 5rem;
    height: 100%;
    z-index: 5;
}

.buttonOverlay1 {
    position: absolute;
    width: 1.8rem;
    height: 100%;
    max-height: 5rem;
    overflow: hidden;
    transform-origin: right;
    transform: scale(-1) rotate(180deg) translate(1.80rem,0);
}

.buttonOverlay2 {
    position: absolute;
    width: 1.8rem;
    max-height: 5rem;
    height: 100%;
    overflow: hidden;
    transform: scale(-1) rotate(180deg)
}
.themeButton1 {
    position: absolute;
    top: 0;
    left: -1.80rem;
    cursor:pointer;
    border:none;
    width:auto;
    overflow:hidden;
    max-width: 4rem;
    max-height: 5rem;
    height: auto;
    object-fit: contain;
    -webkit-filter: var(--filter-thing1);
            filter: var(--filter-thing1);
    transition: 1s -webkit-filter linear;
    transition: 1s filter linear;
    transition: 1s filter linear, 1s -webkit-filter linear;
    
}

 .themeButton2 {
    position: absolute;
    top: 0;
    left: 0rem;
    cursor:pointer;
    border:none;
    width:auto;
    overflow:hidden;
    max-width: 4rem;
    max-height: 5rem;
    height: auto;
    object-fit: contain;
    -webkit-filter: var(--filter-thing2);
            filter: var(--filter-thing2);
    transition: 1s -webkit-filter linear;
    transition: 1s filter linear;
    transition: 1s filter linear, 1s -webkit-filter linear;
}

.about-layout {
    display: grid;
    /* height: 100vh; */
    /* padding-top: max(6rem, 15vmin); */
    justify-content: center;
    justify-items: center;
    /* row-gap: 15px; */
    /* column-gap: 15px; */

    /* grid-template-columns: minmax(20px, 1fr) repeat(2, minmax(0, 100px)) minmax(20px, 1fr); */
    
    grid-template-rows: 5rem auto 1fr auto;
    grid-template-columns: 1fr;

    grid-auto-flow: column;
    /* align-items: flex-start; */
}

.about-text {
    margin: 0 15px;
    font-size: 1rem;
    color: var(--text-on-bg);
    justify-self: start;
}

/* .Reddit {
    width: 100%;
    height: 100%;
    grid-row: var(--grid-row-setup);
    grid-row: 2;
} */

.construction-image {
  object-fit: cover;
  width: 100%;
  max-height: 100%;
}

h1,h2,h3 {
    color: var(--text-on-bg);
    margin-top: 0px;
}


@media (min-width: 45em) {    
    .about-layout {
    grid-template-rows: 5rem auto 1fr auto;
    grid-template-columns: 1fr;
    }

    h3 {
        margin-top: 1rem;
    }
    .about-text {
        font-size: 1.4rem;
        color: var(--text-on-bg);
    }

  }
