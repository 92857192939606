.navbar {
  grid-row: 1;
  grid-column: 1/4;
  height: 100%;
  width: 100%;
  
  
  background-color: var(--horde-secondary)
}

h1 {
  /* color: white;
  font-size: 1em; */
  display:none;
  margin:0;
}

.navbar-nav {
  list-style: none;
  height: 100%;
  padding: 0;
  margin: 0;
  display: grid;
  /* flex-direction: row; */
  grid-auto-flow: column;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
  align-items: center;
  width: 100%;

}

.nav-item {
  margin: 0 .5rem;
}

.nav-item-header {
  grid-column: 4
}

.nav-link {
  color: var(--text-on-bg);
  text-decoration: none;
  text-transform: uppercase;
  font-size: 1.5rem;
  animation: 2s slideUp ease forwards .5s;
  position: relative;
  align-self: center;
}

.theme-btn {
  align-self: flex-start;
  height: 100%;
}

.nav-link-active {
  color: var(--text-on-bg);
  text-decoration: none;
  text-transform: uppercase;
  font-size: 1.5rem;
  animation: 2s slideUp ease forwards .5s;
  position: relative;
}

.nav-link-active::before, .nav-link::before {
  content: '';
  position: absolute;
  height: 4px;
  width: 0px;
  left: 0;
  bottom: -.2rem;
  background: #ffffff;
  transition: all .5s ease;
}

.nav-link-active:before, .nav-link:hover:before {
  width: 100%;
}


@media (min-width: 45em) { 
  h1 {
    font-size: 1.8em;
    display: inline-block;
    margin-left: 1rem
  }

  .nav-item-header {
    grid-column: 1;
    justify-self:  start;
  }

  .navbar-nav {
    grid-template-columns: 4fr auto auto auto;
  }
}