  .Main {
    display: grid;
    height: 100vh;
    /* padding-top: max(6rem, 15vmin); */
    justify-content: center;
    justify-items: center;
    /* row-gap: 15px; */
    /* column-gap: 15px; */

    /* grid-template-columns: minmax(20px, 1fr) repeat(2, minmax(0, 100px)) minmax(20px, 1fr); */
    
    grid-template-rows: 5rem 1fr 1fr 1fr;
    grid-template-columns: 1fr;

    grid-auto-flow: column;
    align-items: center;
}

.Reddit {
    width: 100%;
    height: 100%;
    grid-row: var(--grid-row-setup);
    grid-row: 2;
}
.Discord {
    width: 100%;
    height: 100%;
    grid-row: var(--grid-row-setup);
    grid-row: 3;
}
.WowForums {
    width: 100%;
    height: 100%;
    grid-row: var(--grid-row-setup);
    grid-row: 4;
}

.RowGroup {
    display: grid;
    justify-items: center;
    grid-auto-flow: row;
    row-gap: 15px;
}

.ColumnGroup {
    display: grid;
    align-items: center;
    grid-auto-flow: column;
    column-gap: 15px;
}

h1,h2,h3 {
    margin-top: 0px;
}


@media (min-width: 45em) {    
    .Main {
    grid-template-rows: 5rem 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    }
    .Reddit {
        grid-column: 1;
        grid-row: 2
    }
    .Discord {
        grid-column: 2;
        grid-row: 2
    }
    .WowForums {
        grid-column: 3;
        grid-row: 2
    }
    h3 {
        margin-top: 1rem;
    }
  }