.about-layout {
    display: grid;
    /* height: 100vh; */
    /* padding-top: max(6rem, 15vmin); */
    justify-content: center;
    justify-items: center;
    /* row-gap: 15px; */
    /* column-gap: 15px; */

    /* grid-template-columns: minmax(20px, 1fr) repeat(2, minmax(0, 100px)) minmax(20px, 1fr); */
    
    grid-template-rows: 5rem auto 1fr auto;
    grid-template-columns: 1fr;

    grid-auto-flow: column;
    /* align-items: flex-start; */
}

.about-text {
    margin: 0 15px;
    font-size: 1rem;
    color: var(--text-on-bg);
    justify-self: start;
}

/* .Reddit {
    width: 100%;
    height: 100%;
    grid-row: var(--grid-row-setup);
    grid-row: 2;
} */

.construction-image {
  object-fit: cover;
  width: 100%;
  max-height: 100%;
}

h1,h2,h3 {
    color: var(--text-on-bg);
    margin-top: 0px;
}


@media (min-width: 45em) {    
    .about-layout {
    grid-template-rows: 5rem auto 1fr auto;
    grid-template-columns: 1fr;
    }

    h3 {
        margin-top: 1rem;
    }
    .about-text {
        font-size: 1.4rem;
        color: var(--text-on-bg);
    }

  }